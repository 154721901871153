<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit Haremicon')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.title"
                  class="mt-2"
                  :state="errors.title ?false:null"
                  :placeholder="$t('title')"
                />
                <small
                  v-if="errors.title"
                  class="text-danger"
                >{{ errors.title[0] }}</small>
                <label>{{ $t('title') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.body"
                  class="mt-2"
                  :state="errors.body ?false:null"
                  :placeholder="$t('body')"
                />
                <small
                  v-if="errors.body"
                  class="text-danger"
                >{{ errors.body[0] }}</small>
                <label>{{ $t('body') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      ><b-card>
         <b-row>
           <b-col
             cols="12"
             md=""
             class="d-flex justify-content-center"
           >
             <b-row>
               <b-col
                 cols="12"
                 class="d-flex justify-content-center"
               >
                 <upload
                   v-model="data.img_id"
                   @on-file-error="UpdateFileError"
                 />
               </b-col>
               <b-col cols="12">
                 <b-alert
                   v-if="fileErrors.length>0"
                   variant="danger"
                   show
                 >
                   <h4 class="alert-heading">
                     {{ $t('Upload Image Error') }}
                   </h4>
                   <div class="alert-body">
                     <span>
                       <ul
                         v-for="(val,index) in fileErrors"
                         :key="index"
                       >
                         <li>{{ val }}</li>
                       </ul>
                     </span>
                   </div>
                 </b-alert>
               </b-col>
             </b-row>
           </b-col>
         </b-row>
       </b-card>
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['haremicon/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('haremicon/GetElement', this.$route.params.haremicon).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('haremicon/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
